import { Alert, Button, message } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { get_string } from '../i18n/strings'
import { Context } from '../utils/store'
import moment from "moment-timezone";
import Axios from 'axios';
import { apiUrl } from '../utils/config';
import { names, reservation_response, room, Types } from '../utils/interfaces';
import { handleTokenExpired } from '../utils/utils';

moment.tz.setDefault('Europe/Bratislava')

interface Props {
    room: room

}

type AlertType = {
    type: 'success' | 'error' | 'info',
    id: names
}

export default function InstantReservation({ room }: Props) {
    const [alert, setAlert] = useState<AlertType>({ type: 'info', id: 'drying_info' })

    const { state, dispatch } = useContext(Context)


    useEffect(() => {
        setAlert({ type: 'info', id: 'drying_info' })
    }, [room])

    const handleReserve = () => {

        let start = moment().unix();
        let end = start + 60 * 15;
        console.log(start, end);

        Axios.post<reservation_response>(`${apiUrl}/reservation/1`, {
            roomid: room.id,
            start: start,
            end: end
        }, {
            headers: {
                'Authorization': `JWT ${state.token}`
            }
        })
            .then(res => {

                setAlert({ type: 'success', id: res.data.result_id })
                console.log(res.data);

            })
            .catch(e => {
                let isExpired = handleTokenExpired(e.response?.status, ()=>{dispatch({ type: Types.Logout, payload: null})})
                if(!isExpired){
                    setAlert({ type: 'error', id: e.response.data.result_id })
                    console.log(e.response.data);
                }
            })
    }
    return (
        <div
            className="site-layout-background drying-room"
        >

            <Alert
                style={{ width: '100%' }}
                message={get_string(state.locale, alert.id)}
                className={'mb-10px'}
                type={alert.type}
                showIcon
            />


            <Button type={'primary'} onClick={handleReserve}>
                {get_string(state.locale, 'reserve')}
            </Button>
        </div>
    )
}
