import { message } from "antd";
import { get_string } from "../i18n/strings";

export const handleTokenExpired = (status: number | undefined, callback: Function) =>{
    if(status === 401){
        message.loading(get_string('en', 'token_expired'), 1, ()=>{
            callback();
            console.log('token expired');
            return true;
        })
        
    }

    return false;
}