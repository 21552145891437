import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Empty, message, Space } from "antd";
import { Context } from "../utils/store";
import { get_string } from "../i18n/strings";
import { room, Types } from "../utils/interfaces";
import Axios from "axios";
import InDayRezervation from "./InDayReservation";
import InstantRezervation from "./InstantReservation";
import { apiUrl } from "../utils/config";
import { createFromIconfontCN } from "@ant-design/icons";
import { handleTokenExpired } from "../utils/utils";
const IconFont = createFromIconfontCN({
  scriptUrl: "/assets/font.js",  
//scriptUrl: "//at.alicdn.com/t/font_2048462_iaxq4awi9.js",
});

export default function Room() {
    const { state, dispatch} = useContext(Context)

    let { room_id } = useParams<{ room_id: string }>();
    const [room, setRoom] = useState<room | null>(null);

    useEffect(() => {
        Axios.get<room>(`${apiUrl}/room/${room_id}`, {
            headers: {
                'Authorization': `JWT ${state.token}`
            }
        })
            .then(res => {

                console.log(res.data);

                setRoom(res.data)
            })
            .catch(e => {
                let isExpired = handleTokenExpired(e.response?.status, ()=>{dispatch({ type: Types.Logout, payload: null})})
                if(!isExpired){
                    console.log(e.response.data);
                }

            })
    }, [room_id])

    if (room === null) {
        return <Empty style={{ marginTop: "20px" }} description="Room not found" />
    }


    return (
        <>
            <Space style={{margin: '20px 0px', color: "#464646"}}>
                <IconFont type={`icon-${room.room_type}`} style={{ fontSize: "18px" }} /> 
                {room && get_string(state.locale, room.room_type)} 
                {room?.building + "/" + room?.room_number} 
            </Space>



            <div
                className="site-layout-background"
            >
                {(room && room.reservation_type === 'in-day-reservation') && <InDayRezervation room={room} />}
                {(room && room.reservation_type === 'instant_reservation') && <InstantRezervation room={room} />}


            </div>
        </>
    );
}
