import React, { useContext, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from "react-router-dom";
import "./App.css";
import { Main } from "./components/Main";
import Login from "./components/Login";
import { Context } from "./utils/store";
import Dashboard from "./components/Dashboard"; // Предположим, что у вас есть компонент Dashboard
import Room from "./components/Room"; 

const App: React.FC = () => {
  const { state } = useContext(Context);
  const history = useHistory();

  // Получаем user_id из localStorage и преобразуем его в число
  const userId = Number(localStorage.getItem('user_id'));

  useEffect(() => {
    console.log('User ID from localStorage:', userId);
    console.log('State:', state);

    if (userId === 89995) {
      history.push('/dashboard');
    }
  }, [userId, state, history]);

  return (
    <Switch>
      <Route exact path='/'>
        {state.token ? <Main /> : <Login />}
      </Route>
      <Route path='/dashboard'>
        {userId === 89995 && state.token ? <Dashboard /> : <Login />}
      </Route>
      <Route path='/room/:room_id'> {/* <-- Добавьте этот маршрут */}
        <Main />
      </Route>
      <Redirect to="/" />
    </Switch>
  );
};

const AppWrapper: React.FC = () => (
  <Router basename='/frontend'>
    <App />
  </Router>
);

export default AppWrapper;

