import React, { useContext, useEffect, useState } from "react";
import { DatePicker, Table, Layout, Typography } from "antd";
import moment, { Moment } from "moment";
import Axios from "axios";
import { Context } from "../utils/store";
import { apiUrl } from "../utils/config";
import { handleTokenExpired } from "../utils/utils";
import { room } from "../utils/interfaces";
import { get_string } from "../i18n/strings";
import { Types } from "../utils/interfaces";

const { Header, Content } = Layout;
const { Title } = Typography;

interface DataType {
  key: string;
  [key: string]: any;
}

const Dashboard: React.FC = () => {
  const { state, dispatch } = useContext(Context);
  const [selectedDate, setSelectedDate] = useState<Moment | null>(null);
  const [rooms, setRooms] = useState<room[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [reservations, setReservations] = useState<any[]>([]);

  const handleDateChange = (date: Moment | null) => {
    setSelectedDate(date);
    if (date) {
      fetchReservations(date);
    }
  };

  const getRoomLabel = (id: number): string => {
    if (id === 22) {
      return 'Posilňovňa';
    } else if (id === 23) {
      return 'Fitness';
    } else if (id === 3) {
      return 'Študovňa';
    } else if (id === 4) {
      return 'Herňa';
    }
    return '';
  };



  useEffect(() => {
    if (state.locale !== 'sk') {
      dispatch({ type: Types.SetLocale, payload: 'sk' });
    }


    Axios.get<room[]>(`${apiUrl}/rooms/`, {
      headers: {
        'Authorization': `JWT ${state.token}`
      }
    })
      .then((res) => {
        const rooms = res.data.filter(room => room.reservation_type !== 'not_required');
        setRooms(rooms);

        const blueTextStyle = {
          color: '#fff', 
          backgroundColor: '#1890ff',
          padding: '4px 8px',
          borderRadius: '4px',
          display: 'inline-block' // Добавьте это свойство
        };

        const dynamicColumns = rooms.map(room => ({
          title: `${room.building} / ${room.room_number} (${getRoomLabel(room.id)})`,
          dataIndex: `${room.building}_${room.room_number}`,
          key: `${room.id}`,
          render: (text: string) => (
            text ? <div style={blueTextStyle}>{text}</div> : null
          ),
        }));

        setColumns(dynamicColumns);
        setSelectedDate(moment());
        fetchReservations(moment());
      })
     // .catch((e) => {
      //  let isExpired = handleTokenExpired(e.response?.status, () => { dispatch({ type: Types.Logout, payload: null }) });
       // if (!isExpired) {
        //  console.log(e.response);
       // }
     // });
  }, [state.token, dispatch]);

useEffect(() => {
  if (rooms.length > 0 && selectedDate) {
    fetchReservations(selectedDate);
  }
}, [rooms, selectedDate]);

const fetchReservations = async (date: Moment) => {
  try {
    const roomReservations = await Promise.all(
      rooms.map(async (room) => {
        const pickedDateCopy = moment(date);

        let start = moment(room.start || '06:00:00', "H:mm:ss");
        let end = moment(room.end || '22:00:00', "H:mm:ss");

        const unixStartTime = pickedDateCopy.set({ hour: start.hour(), minute: start.minute(), second: start.second() }).unix();
        const unixEndTime = pickedDateCopy.set({ hour: end.hour(), minute: end.minute(), second: end.second() }).unix();

        const res = await Axios.get(`${apiUrl}/reservation/find/?roomid=${room.id}&start=${unixStartTime}&end=${unixEndTime}`, {
          headers: {
            'Authorization': `JWT ${state.token}`
          }
        });

        return res.data.map((reservation: any) => ({
          ...reservation,
          roomKey: `${room.building}_${room.room_number}`,
          user: `${reservation.user.firstname} ${reservation.user.lastname}`,
          startTime: moment.unix(reservation.start).format('HH:mm'),
          endTime: moment.unix(reservation.end).format('HH:mm')
        }));
      })
    );

    const sortedReservations = roomReservations.flat().sort((a, b) => a.start - b.start);
    setReservations(sortedReservations);

  } catch (e) {
   // let isExpired = handleTokenExpired(e.response?.status, () => { dispatch({ type: Types.Logout, payload: null }) });
   // if (!isExpired) {
     // console.log(e.response);
   // }
  }
};

 const dataSource = reservations.map((reservation, index) => {
  const { roomKey, user, startTime, endTime } = reservation;
  return { key: index, [roomKey]: `${user} (${startTime} - ${endTime})` };
});

  console.log(dataSource);

  return (
    <Layout style={{ height: "100vh", background: "#C0C0C0" }}>
      <Header style={{ color: "#fff" }}>
        <Title level={3} style={{ color: "#fff", marginTop: "15px" }}>
          Dashboard rezervacie
        </Title>
      </Header>
      <Content style={{ padding: "20px" }}>
        <div style={{ marginBottom: "20px" }}>
          <DatePicker
            onChange={handleDateChange}
            value={selectedDate}
            style={{ marginRight: "10px" }}
          />
          {selectedDate && (
            <span>
              Vybraný dátum: {moment(selectedDate).format("YYYY-MM-DD")}
            </span>
          )}
        </div>
        <Table
          dataSource={dataSource}
          columns={columns}
          size="small"
          bordered
        />
      </Content>
    </Layout>
  );
};

export default Dashboard;

