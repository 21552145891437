import {Strings, names} from '../utils/interfaces'
import React from "react";
import {Alert} from "antd";

const info_en = <p style={{textAlign: 'justify', fontSize: '16px'}}>
    As a student living in the J13 Dormitory and a member of OZ J13, you can use the common rooms provided by the 
    Student Council of J13. There are a total of 4 rooms available: Gym, Fitness, Study Room, and Game Room. 
    The process is simple - at the beginning of the semester, during the announced registration period (internet, laundry rooms, gyms), 
    you register and pay a fee to the Civic Association ŠR J13, a part of which is used for the maintenance and renovation of the rooms.
    <br/>You can borrow the key at the reception after reserving the room. Without a reservation, the reception will not 
    give you the key. If you want to take advantage of this opportunity, simply click on the reservation page, reserve 
    a time and the room that suits you best. <strong>The maximum usage time for all rooms for one student is 12 hours per week. 
    You can decide for yourself how to use this time.</strong>
    <br/>Pay attention to adhering to the chosen times! In case of violation of the room usage rules or failing to 
    respect the end of the reservation period, your account may be blocked, and you will be prevented from entering 
    the common rooms for a period of one month. The usage rules are posted in each room.    
    </p>

const info_sk = <p style={{textAlign: 'justify', fontSize: '16px'}}>
    Ako študent ubytovaný v ŠD J13 a člen OZ J13 môžeš využívať spoločenské miestnosti poskytované 
    Študentskou Radou J13. Celkovo sú k dispozícii 4 miestnosti: Posilňovňa, Fitness, Študovňa a Herňa. 
    Postup je jednoduchý - začiatkom semestra sa počas vopred ohláseného zápisu (internet, práčovne, posilňovne) 
    zaregistruješ a zaplatíš poplatok do Občianskeho združenia ŠR J13, ktorého časť sa používa na údržbu a vynovovanie miestností.
    <br/>Kľúč si môžeš zapožičať na vrátnici po rezervovaní miestnosti. Bez rezervácie vrátnica kľúč nevydá. 
    Ak budeš chcieť využiť túto príležitosť, jednoducho klikneš na stránku rezervacie.intrak.sk, zarezervuješ si čas a miestnosť, 
    ktorá ti najviac vyhovuje. <strong>Maximálny čas používania všetkých miestností pre jedného študenta je 12 hodín týždenne. 
    Môžeš si sám rozhodnúť, ako tento čas využiješ.</strong>
    <br/>Dávaj pozor na dodržiavanie zvolených časov! V prípade porušenia pravidiel používania miestností 
    alebo nedodržania konca doby rezervácie môže byť tvoj účet zablokovaný a následne ti bude znemožnené vstupovať do spoločenských miestností 
    po dobu jedného mesiaca. Pravidlá používania sú vyvesené na každej jednej miestnosti.

</p>

const en : Strings = {
    login_info: 'Your login is the same as on the userpanel',
    laundry: 'Laundry',
    relax: 'Relax',
    info: 'Info',
    drying: 'Drying',
    sport: 'Sport',
    account: 'Account', 
    reserve: 'Reserve',
    drying_info: 'You can access the drying room not further than 36 hours after your last laudry reservation',
    main_info: <Alert message={info_en}
                      type={'info'}
                      
                    
    />,
    start_time: 'Start time',
    end_time: 'End time',
    logout: 'Logout',
    study_room: 'Study room',
    no_reservations: 'There are no reservations',
    reservation_ok: 'Reservation successful',
    err_reservation_pending: 'You already have a reservation',
    err_reservation_overlap: 'Reservation overlapping',
    err_reservation_start_end: 'Invalid time',
    err_reservation_future: "Invalid time",
    err_drying_only_after_laundry: "You can only reserve a drying room during 36 hours after your last laundry reservation",
    token_expired: 'Session is expired, redirect to login page',
    bugs_errors: 'Found bugs or errors? Let us know',
    err_quota: 'This reservation would exceed your quota',
    gym: 'Gym',
    fitness: 'Fitness',
    gaming: 'Gaming room',
    study: 'Study room',
    rule_gaming_room: '',
    rule_sport_room: ''
}

const sk = {
    login_info: 'Prihlasovacie údaje sú rovnaké ako na userpanel',
    laundry: 'Práčovňa',
    relax: 'Relax/Herňa',
    info: 'Informácie',
    drying: 'Sušiareň',
    sport: 'Šport',
    account: 'Konto',
    reserve: 'Zarezervovať',
    drying_info: 'Sušiareň si môžete zarezervovať do 36 hodín po poslednej rezervácii práčovne',
    main_info: <Alert message={info_sk}
                      type={'info'}
                      
                       />,
    start_time: 'Začiatok',
    end_time: 'Koniec',
    logout: 'Odhlásiť sa',
    study_room: 'Študovňa',
    no_reservations: 'Žiadne rezervácie',
    reservation_ok: 'Rezervácia úspešná',
    err_reservation_pending: 'Už máte jednu rezerváciu',
    err_reservation_overlap: "Rezervácie sa prekrývajú",
    err_reservation_start_end: "Nesprávny čas",
    err_reservation_future: "Nesprávny čas",
    err_drying_only_after_laundry: "Sušiareň je možné rezervovať najviac 36 hodín po poslednej rezervácii práčovne",
    token_expired: 'Čas relácie vypršal, prihlaste sa este raz',
    bugs_errors: 'Našli ste chyby? Dajte nám vedieť',
    err_quota: 'Táto rezervácia by prekročila vašu kvótu',
    gym: 'Posilňovňa',
    fitness: 'Fitness',
    gaming: 'Herňa',
    study: 'Študovňa',
    rule_gaming_room: '',
    rule_sport_room: ''
}

const ua = {
    login_info: 'Дані для входу такі ж, як на userpanel',
    laundry: 'Пральня',
    relax: 'Ігрова',
    info: 'Інформація',
    drying: 'Сушарня',
    sport: 'Фітнес/Тренажерний зал',
    account: 'Обліковий запис',
    reserve: 'Зарезервувати',
    drying_info: 'Сушарню можна зарезервувати протягом 36 годин після останнього бронювання пральні',
    main_info: <Alert message={info_sk}
                      type={'info'}


                        />,
    start_time: 'Початок',
    end_time: 'Кінець',
    logout: 'Вийти',
    study_room: 'Навчальна',
    no_reservations: 'Немає резервацій',
    reservation_ok: 'Резервація успішна',
    err_reservation_pending: 'Ви вже маєте одну резервацію',
    err_reservation_overlap: "Колізія резервацій",
    err_reservation_start_end: "Невірний час",
    err_reservation_future: "Невірний час",
    err_drying_only_after_laundry: "Сушарню можна зарезервувати протягом 36 годин після останнього бронювання пральні",
    token_expired: 'Час сесії минув, будь ласка, увійдіть знову',
    bugs_errors: 'Знайшли помилки? Повідомте нам',
    err_quota: 'Дана резервація перевищує надану квоту'
}

const get_string = (locale:'en'|'sk'|'ua', name:names):string | JSX.Element =>{
    
    if(locale === 'en'){
        return en[name]
    }
    if(locale === 'sk'){
        return sk[name]
    }
   // if(locale === 'ua'){
     //   return ua[name]
   // }

    return name
}

export {get_string}


